<template>
  <div class="profile">
    <error :err.sync="error" />
    <spinner v-if="loading" />
    <template v-else>
      <form-generator
        :elements="elements"
        :data="profile"
        :handleUpdate="({ key }, value) => (profile[key] = value)"
      />
    </template>
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";

export default {
  components: {
    FormGenerator
  },
  data() {
    return {
      loading: false,
      error: "",
      userId: "",
      profile: {
        name: "",
        email: "",
        password: "",
        passwordRepeat: ""
      }
    };
  },
  async mounted() {
    this.loading = true;
    const res = await this.$store.dispatch("users/current");

    const serverData = res[0].data;
    this.userId = serverData.id;

    this.profile.name = serverData.name;
    this.profile.email = serverData.email;

    this.loading = false;
  },
  methods: {
    async save() {
      this.error = "";

      if (!this.isValidEmail(this.profile.email)) {
        this.error += "Please enter a valid email address. ";
      }

      if (
        this.profile.password !== "" &&
        this.profile.password !== this.profile.passwordRepeat
      ) {
        this.error += "Passwords do not match. ";
      }

      if (this.profile.password !== "") {
        if (this.profile.password.length < 8) {
          this.error += "The new password must be at-least 8 characters long. ";
        } else if (!/\d/.test(this.profile.password)) {
          this.error +=
            "The new password must contain at-least one numeric digit. ";
        } else if (!/[A-Z]/.test(this.profile.password)) {
          this.error +=
            "The new password must contain at-least one uppercase character. ";
        }
      }

      if (this.error !== "") {
        return false;
      }

      this.loading = true;

      let payload = {
        name: this.profile.name,
        email: this.profile.email
      };

      if (this.profile.password !== "") {
        payload.password = this.profile.password;
      }

      await this.$store.dispatch("users/updateCurrent", payload);
      this.loading = false;
    },

    isValidEmail(input) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(input);
    }
  },
  computed: {
    elements() {
      return [
        {
          text: "You may update your basic profile here.",
          type: "paragraph"
        },
        {
          type: "email",
          key: "email",
          label: "email"
        },
        {
          type: "string",
          key: "name",
          label: "Display name (Full)"
        },
        {
          type: "divider",
          text: "Update password",
          className: "mt-5"
        },
        {
          text:
            "Enter a new password if you would like to change it. Leave the Password fields blank otherwise.",
          type: "paragraph"
        },
        {
          key: "password",
          label: "password",
          type: "password",
          required: true
        },
        {
          key: "passwordRepeat",
          label: "Re-type Password",
          type: "password",
          required: true
        },
        {
          type: "button",
          text: "save",
          className: "mt-3 mt-md-0 float-right px-4",
          attrs: {
            loading: this.loading
          },
          events: {
            click: this.save
          }
        }
      ];
    }
  },
  name: "profile"
};
</script>

<style></style>
